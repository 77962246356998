import { useState } from 'react';
import { Link } from 'react-router-dom';

import { News } from 'assets/icons';
import { RoutesData } from 'types';
import { cutStringByLength } from 'utils';

import styles from './NewsItem.module.scss';

const displayNoneStyle = { display: 'none' };

const NewsItem = ({ date = '', details = '', title = '', id, type, img }: any) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const style = isLoaded ? {} : displayNoneStyle;

  const controlLength = type === 'small' ? 55 : 180;
  const infoToCut = type === 'small' ? title : details;

  const content = cutStringByLength(infoToCut, 0, controlLength, controlLength) || '';

  if (type === 'small')
    return (
      <div className={styles.border}>
        <Link to={`${RoutesData.Dashboard}/news/${id}`} className={styles.wrapper_small}>
          <div className={styles.wrapper_small__left_side}>
            <span className={styles.wrapper_small__left_side__title}>{content}</span>
            <span className={styles.wrapper_small__left_side__date}>{date}</span>
          </div>
          <div className={styles.wrapper_small__right_side}>
            <img
              className={styles.img}
              onLoad={() => setIsLoaded(true)}
              src={img}
              alt={id}
              style={style}
            />
            {isLoaded ? null : <News />}
          </div>
        </Link>
      </div>
    );

  return (
    <div className={styles.wrapper_big__news}>
      <div className={styles.wrapper_big__news__info}>
        <h1 className={styles.title}>{title}</h1>
        <div className={styles.content} dangerouslySetInnerHTML={{ __html: content }} />
        <Link to={`${RoutesData.Dashboard}/news/${id}`} className={styles.link}>
          Read more
        </Link>
        <span className={styles.date}>{date}</span>
      </div>
      <div className={styles.wrapper_big__news__img}>
        <img
          className={styles.img}
          onLoad={() => setIsLoaded(true)}
          src={img}
          alt={id}
          style={style}
        />
        {isLoaded ? null : <News />}
      </div>
    </div>
  );
};

export default NewsItem;
